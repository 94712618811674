import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Spinner } from 'reactstrap'

const LiveEventButton = () => {
  return (
    <Link to='/live-shows'>
      <Button color='success' size="md" title='Live Placing Started'>
         LIVE PLACING STARTED
        {/* <Spinner  size="sm" className='ms-2' type="grow" /> */}
      </Button>
    </Link>
  )
}

export default LiveEventButton
