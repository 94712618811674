import _ from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Table } from 'reactstrap'

const Placing = ({ tabType, openActiveTab, recordType }) => {
  const { exhibitorList, activeTabs, participationList, participationLoading } =
    useSelector((state) => {
      return {
        participationList:
          state?.LiveEvent?.participationData?.participationList || [],
        participationLoading: state?.LiveEvent?.participationData?.loading,

        // /////////////////////////////////
        exhibitorList: state?.LiveEvent?.exhibitorData?.exhibitorList || [],
        exhibitorLoading: state?.LiveEvent?.exhibitorData?.loading,

        // /////////////////////////////////
        activeTabs: _.find(
          state?.LiveEvent?.activeTabs,
          (g) => g.type === tabType
        ),
      }
    })

  const ParticipationList = useMemo(() => {
    return _.filter(participationList, (p) => {
      const matchObj = _.isMatch(
        p,
        _.omit(_.pickBy(activeTabs, _.identity), ['type'])
      )

      return matchObj
    })
  }, [activeTabs, participationList, tabType, openActiveTab])

  return (
    <Table responsive className='table cust-tab table'>
      <thead className='table-color'>
        <tr>
          <th>Place</th>
          <th>Ear No</th>
          <th>Name</th>
          {['fur', 'furColor'].includes(tabType) && recordType === 'breed' ? (
            <th>Group / Variety / Class</th>
          ) : null}
          {['fur', 'furColor'].includes(tabType) &&
          recordType === 'fur_as_breed' ? (
            <th>Breed / Group / Variety / Class</th>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {openActiveTab &&
        !participationLoading &&
        Array.isArray(ParticipationList) &&
        ParticipationList.length ? (
          ParticipationList.map((participant) => {
            const exhibitor = _.find(
              exhibitorList,
              (e) => e.participant_id === participant.participant_id
            )

            return (
              <tr key={participant?.participant_id}>
                <td>{participant.placing}</td>
                <td>
                  {participant.placing === '1'
                    ? '******'
                    : exhibitor?.ear_no || '-'}
                </td>
                <td>
                  {participant.placing === '1'
                    ? '******'
                    : exhibitor?.user_full_name || (
                        <span className='text-red'>Deleted User</span>
                      )}
                </td>
                {['fur', 'furColor'].includes(tabType) &&
                recordType === 'breed' ? (
                  <td>
                    {_.join(
                      _.compact([
                        exhibitor?.group_name || '',
                        exhibitor?.variety_name || '',
                        exhibitor?.class_name || '',
                      ]),
                      ', '
                    )}
                  </td>
                ) : null}
                {['fur', 'furColor'].includes(tabType) &&
                recordType === 'fur_as_breed' ? (
                  <td>
                    {_.join(
                      _.compact([
                        exhibitor?.breed_name || '',
                        exhibitor?.group_name || '',
                        exhibitor?.variety_name || '',
                        exhibitor?.class_name || '',
                      ]),
                      ', '
                    )}
                  </td>
                ) : null}
              </tr>
            )
          })
        ) : participationLoading ? (
          <tr>
            <td colSpan={5} className='text-center'>
              <Spinner />
            </td>
          </tr>
        ) : ParticipationList.length === 0 ? (
          <tr>
            {' '}
            <td colSpan={5} className='text-center'>
              No exhibitor found
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  )
}

export default Placing
