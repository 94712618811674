// Authentication related pages
import AdditionalProducts from '../pages/AdditionalProducts/AdditionalProducts'
import ChangePwd from '../pages/Authentication/ChangePassword/index'
import ForgetPwd from '../pages/Authentication/ForgetPassword/index'
import Login from '../pages/Authentication/login/index'
import UserLogout from '../pages/Authentication/Logout/index'
import Register from '../pages/Authentication/Register/index'
import UserProfile from '../pages/Authentication/UserProfile'
import Dashboard from '../pages/Dashboard'
import DISCOUNT_SUMMARY from '../pages/Discount/DiscountSummary'
import EasyEntry from '../pages/EasyEntry/Index'
import Events from '../pages/Events/index'
import ParticipateEasyEntry from '../pages/ParticipateEasyEntry/Components/ParticipateEasyEntry'
// import ParticipateEasyEntryEdit from '../pages/ParticipateEasyEntry/Components/ParticipateEasyEntryEdit'
import ParticipateEasyEntryPreview from '../pages/ParticipateEasyEntry/Components/ParticipateEasyEntryPreview'
import ParticipateManualEntry from '../pages/ParticipateManualEntry/Components/ParticipateManualEntry'
// import ParticipateManualEntryEdit from '../pages/ParticipateManualEntry/Components/ParticipateManualEntryEdit'
import ParticipateManualEntryPreview from '../pages/ParticipateManualEntry/Components/ParticipateManualEntryPreview'
import CHECKOUT from '../pages/ShoppingCart/Checkout'
// import ParticipateEasyEntryPreview from '../pages/ParticipateEasyEntry/Components/Preview';
// Shopping Cart
import SHOPPING_CART from '../pages/ShoppingCart/ShoppingCart'
import SubUserForm from '../pages/SubUser/Components/CreateSubUser'
import EditSubUser from '../pages/SubUser/Components/EditSubUser'
import SubUserList from '../pages/SubUser/Components/SubUserList'
import VerifyAccount from '../pages/VerifyAccount/Modal'
import EDIT_PARTICIPATE_EVENTS from '../pages/YourParticipation/Components/EditParticipateEvents'
import PARTICIPATE_EVENTS from '../pages/YourParticipation/Components/ParticipateEvents'
// Your Participation
import PARTICIPATION from '../pages/YourParticipation/Participation'
import PREVIEW_PARTICIPATE_EVENTS from '../pages/YourParticipation/Components/PreviewParticipateEvents'
import PARTICIPATE_SHPPING_CART from '../pages/YourParticipation/Components/ShoppingCart'
import PARTICIPATE_CHECKOUT from '../pages/YourParticipation/Components/Checkout'

import TraditionalPlan from '../pages/PlanPurchase/Traditional/Index'
import TraditionalPlanForm from '../pages/PlanPurchase/Traditional/Form'

import HybridPlan from '../pages/PlanPurchase/Hybrid/Index'
import HybridPlanForm from '../pages/PlanPurchase/Hybrid/Form'

import IntegratedPlan from '../pages/PlanPurchase/Integrated/Index'
import IntegratedPlanForm from '../pages/PlanPurchase/Integrated/Form'

import RegisterSecretary from '../pages/PlanPurchase/RegisterSecretary'
import PlanShoppingCart from '../pages/PlanPurchase/ShoppingCart'
import PlanCheckout from '../pages/PlanPurchase/Checkout'
import ProcessPayment from '../pages/PlanPurchase/PaymentProcess'
// Sponsor Sanction
import SponsorSanction from '../pages/SponsorSanction/Index'
import SponsorSanctionView from '../pages/SponsorSanction/View'
import SponsorSanctionShoppingCart from '../pages/SponsorSanction/ShoppingCart'
import Checkout from '../pages/SponsorSanction/checkout'
import ClerkLogin from '../pages/Authentication/ClerkLogin/ClerkLogin'
import EventReports from '../pages/EventReports'
import TraditionalPlanRenewal from '../pages/Traditional_plan_renewal'
import TraditionalPlanRenewalRedirect from '../pages/Traditional_plan_renewal/TraditionalPlanRenewalRedirect'

import TermsConditions from '../pages/Terms/Index'

// Easy Fieds
import AnimalListing from '../easyfieds/AnimalListing'
import AnimalContact from '../easyfieds/AnimalContact/Contact'
import JudgeContact from '../easyfieds/Judges/Contact'

import JudgeListing from '../easyfieds/Judges/JudgeListing'

import EasyFidesContact from '../easyfieds/Contact/Index'

import EquipmentListing from '../easyfieds/Equipment/EquipmentListing'

import EquipmentReadMore from '../easyfieds/Equipment/ReadMore'
import EquipmentContact from '../easyfieds/Equipment/Contact'

// Profile
import Profile from '../easyfieds/Profile/Profile'
import PremiumMembership from '../pages/PremiumMembership/PremiumMembership'
import PremierPlans from '../pages/Authentication/PremierPlans/PremierPlans'
import PremierCheckout from '../pages/PremiumMembership/PremierCheckout/PremierCheckout'
import PremierReport from '../pages/Authentication/PremierReport/PremierReport'
import PremierReportShowList from '../pages/Authentication/PremierReport/ShowList/ShowList'

////////////////////////////////////////////////////////////////
// import LiveEventTicker from '../pages/LiveEventTicker/LiveEventTicker'
import LiveEventPlacingList from '../pages/Authentication/LiveEventTicker/ShowList/ShowList'
import PurchasedRedirect from '../pages/PremiumMembership/PurchasedRedirect/PurchasedRedirect'

// Easy Fieds Judge

const authRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/auth-events', component: Events },
  { path: '/subuserlist', component: SubUserList },
  { path: '/add-subuser', component: SubUserForm },
  { path: '/edit-profile', component: UserProfile },
  { path: '/change-password', component: ChangePwd },
  { path: '/edit-subuser', component: EditSubUser },
  { path: '/easy-entry', component: EasyEntry },
  { path: '/additional_products/', component: AdditionalProducts },
  { path: '/participate_easy_entry', component: ParticipateEasyEntry },
  // { path: '/participate_easy_entry_edit', component: ParticipateEasyEntryEdit },
  { path: '/user/terms_conditions', component: TermsConditions },
  {
    path: '/participate_easy_entry_preview',
    component: ParticipateEasyEntryPreview,
  },
  { path: '/participate_manual_entry', component: ParticipateManualEntry },
  // {
  //   path: '/participate_manual_entry_edit',
  //   component: ParticipateManualEntryEdit,
  // },
  {
    path: '/participate_manual_entry_preview',
    component: ParticipateManualEntryPreview,
  },
  // { path: '/easy_entry_preview', component: ParticipateEasyEntryPreview },
  //shopping cart
  { path: '/shopping_cart', component: SHOPPING_CART },
  { path: '/checkout', component: CHECKOUT },
  //Discount
  { path: '/discount_summary', component: DISCOUNT_SUMMARY },
  // Your Participation
  { path: '/your_participation', component: PARTICIPATION },
  {
    path: '/your_participation/:eventId/:showId',
    component: PARTICIPATE_EVENTS,
  },
  {
    path: '/your_participation/:eventId/:showId/update/:participateId',
    component: EDIT_PARTICIPATE_EVENTS,
  },

  {
    path: '/your_participation/:eventId/:showId/preview_participate_events',
    component: PREVIEW_PARTICIPATE_EVENTS,
  },
  {
    path: '/your_participation/:eventId/:showId/participate_shopping_cart',
    component: PARTICIPATE_SHPPING_CART,
  },
  {
    path: '/your_participation/:eventId/:showId/participate_checkout',
    component: PARTICIPATE_CHECKOUT,
  },

  // Sponsor Sanction
  { path: '/sponsor_sanction', component: SponsorSanction },
  { path: '/sponsor_sanction/:id', component: SponsorSanctionView },
  {
    path: '/sponsor_sanction_shopping_cart',
    component: SponsorSanctionShoppingCart,
  },
  {
    path: '/sponsor_sanction_checkout',
    component: Checkout,
  },

  {
    path: '/premier-membership/checkout',
    component: PremierCheckout,
  },
  {
    path: '/premier-membership/premier-membership-purchased',
    component: PurchasedRedirect,
  },

  {
    path: '/premier-membership-renew/checkout',
    component: PremierCheckout,
  },
  {
    path: '/premier-membership-auth',
    component: PremiumMembership,
  },
  {
    path: '/purchased-premier',
    component: PremierPlans,
  },
  // { path: "/terms_conditions", component: TermsConditions },
  // {
  //   path: '/live-shows/placing',
  //   component: LiveEventPlacingList,
  // },

  //Easy Fieds
]
const premierUserRoutes = [
  {
    path: '/premier-report',
    component: PremierReport,
  },
  {
    path: '/premier-report/:eventId',
    component: PremierReportShowList,
  },
  {
    path: '/live-shows',
    component: LiveEventPlacingList,
  },
]
const easyfidesRoutes = [
  {
    path: '/easyfides',
    component: AnimalListing,
  },
  {
    path: '/easyfides/contact/:ID',
    component: AnimalContact,
  },
  {
    path: '/easyfides/judges',
    component: JudgeListing,
  },
  {
    path: '/easyfides/judges/contact/:ID',
    component: JudgeContact,
  },

  {
    path: '/easyfides/equipment',
    component: EquipmentListing,
  },
  {
    path: '/easyfides/equipment/equipment_read_more/:ID',
    component: EquipmentReadMore,
  },
  {
    path: '/easyfides/equipment/contact/:ID',
    component: EquipmentContact,
  },
  {
    path: '/easyfides/contact',
    component: EasyFidesContact,
  },

  {
    path: '/easyfides/profile',
    component: Profile,
  },
  {
    path: '/easyfides/profile/judge',
    component: Profile,
  },
  {
    path: '/easyfides/profile/judge/create',
    component: Profile,
  },
  {
    path: '/easyfides/profile/judge/update/:ID',
    component: Profile,
  },
  {
    path: '/easyfides/profile/judge/inquiry',
    component: Profile,
  },
  {
    path: '/easyfides/profile/equipments',
    component: Profile,
  },
  {
    path: '/easyfides/profile/equipments/create',
    component: Profile,
  },
  {
    path: '/easyfides/profile/equipments/inquiry',
    component: Profile,
  },
  {
    path: '/easyfides/profile/equipments/update/:ID',
    component: Profile,
  },
  {
    path: '/easyfides/profile/equipments/equipment_image/:ID',
    component: Profile,
  },
  {
    path: '/easyfides/profile/my_animal',
    component: Profile,
  },
  {
    path: '/easyfides/profile/my_animal/create',
    component: Profile,
  },
  {
    path: '/easyfides/profile/my_animal/update/:ID',
    component: Profile,
  },
  {
    path: '/easyfides/profile/my_animal/animal_image/:ID',
    component: Profile,
  },
  {
    path: '/easyfides/profile/my_animal/inquiry',
    component: Profile,
  },
  {
    path: '/easyfides/profile/inquiry',
    component: Profile,
  },
  { path: '/easyfides/terms_conditions', component: TermsConditions },
]
const UnAuthRoutes = [
  { path: '/logout', component: UserLogout },
  { path: '/login', component: Login },
  { path: '/clerk_login', component: ClerkLogin },
  { path: '/', component: Events },

  { path: '/traditional_plan/licences', component: TraditionalPlan },
  { path: '/traditional_plan', component: TraditionalPlanForm },

  { path: '/hybrid_plan/shows', component: HybridPlan },
  { path: '/hybrid_plan/', component: HybridPlanForm },

  { path: '/integrated_plan/shows', component: IntegratedPlan },
  { path: '/integrated_plan', component: IntegratedPlanForm },

  { path: '/register-secretary', component: RegisterSecretary },

  { path: '/plan_shopping_cart', component: PlanShoppingCart },
  { path: '/plan_checkout', component: PlanCheckout },
  { path: '/process-payment', component: ProcessPayment },

  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/events', component: Events },
  { path: '/verify-account', component: VerifyAccount },
  { path: '/sponsor_sanctions', component: SponsorSanction },
  { path: '/sponsor_sanctions/:id', component: SponsorSanctionView },
  { path: '/event_report/:id', component: EventReports },
  {
    path: '/traditional_plan_renewal',
    component: TraditionalPlanRenewal,
  },
  {
    path: '/traditional_plan_renewal_redirect',
    component: TraditionalPlanRenewalRedirect,
  },
  {
    path: '/premier-membership',
    component: PremiumMembership,
  },
  { path: '/terms_conditions', component: TermsConditions },
  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
]

export { authRoutes, UnAuthRoutes, easyfidesRoutes, premierUserRoutes }
