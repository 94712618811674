import React, { useState } from 'react'
import { Col, Modal, Row } from 'reactstrap'
import GRecaptcha from '../../GRecaptcha'
import { toast } from 'react-toastify'
import { post } from '../../../helpers/api_helper'

const alphabetRegex = /^[a-zA-Z]+$/

let experss =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const emailRegex = new RegExp(experss)

function ContactUs() {
  const [input, setInput] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
  })
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
  })
  const [showModal, setShowModal] = useState(false)
  const [GRC, setGRC] = useState(null)

  const handleGetInTouch = async (e) => {
    e.preventDefault()
    const token = await GRC.current.executeAsync()
    let err = {
      first_name: '',
      last_name: '',
      email: '',
      message: '',
    }
    let validationFlag = true

    if (!input?.first_name?.trim()) {
      validationFlag = false
      err.first_name = 'First Name is required'
    } else if (!alphabetRegex.test(input?.first_name?.trim())) {
      validationFlag = false
      err.first_name = 'First name must be alphabetic'
    } else if (input?.first_name?.trim().length > 50) {
      validationFlag = false
      err.first_name = 'First name must be less than 50 characters'
    }

    if (!input?.last_name?.trim()) {
      validationFlag = false
      err.last_name = 'Last Name is required'
    } else if (!alphabetRegex.test(input?.last_name?.trim())) {
      validationFlag = false
      err.last_name = 'Last name must be alphabetic'
    } else if (input?.last_name?.trim().length > 50) {
      validationFlag = false
      err.last_name = 'Last name must be less than 50 characters'
    }

    if (!input?.email?.trim()) {
      validationFlag = false
      err.email = 'Email is required'
    } else if (!emailRegex.test(input?.email?.trim())) {
      validationFlag = false
      err.email = 'Invalid email address'
    }

    // if (!input?.message?.trim()) {
    //   validationFlag = false
    //   err.message = 'Message is required'
    // } else
    if (input?.message?.trim().length > 500) {
      validationFlag = false
      err.message = 'Message must be less than 500 characters'
    }
    setError(err)

    if (validationFlag && token) {
      try {
        document
          .getElementById('btn-get-in-touch')
          .setAttribute('disabled', true)
        const data = {
          first_name: input?.first_name?.trim(),
          last_name: input?.last_name?.trim(),
          email: input?.email?.trim(),
          message: input?.message?.trim(),
          c_type: 'time2enter',
        }

        const res = await post(`/api/v1/events/getInTouchMaster`, data)
        if (res) {
          setTimeout(() => {
            setShowModal(false)
            setInput({
              first_name: '',
              last_name: '',
              email: '',
              message: '',
            })
          }, 1000)
          toast.success('Email sent successfully')
        }
      } catch (err) {
        document.getElementById('btn-get-in-touch').removeAttribute('disabled')
        if (err?.response?.data) {
          toast.error(err?.response?.data)
        }
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  return (
    <React.Fragment>
      <div
        className='col-md-2'
        role='button'
        onClick={() => setShowModal(!showModal)}
      >
        Contact Us
      </div>
      <Modal isOpen={showModal} centered={true}>
        <div className='modal-header' style={{ background: '#fff' }}>
          <h4 className='modal-title mt-0'>Get in Touch</h4>
          <button
            type='button'
            onClick={() => {
              setShowModal(!showModal)
              setInput({
                first_name: '',
                last_name: '',
                email: '',
                message: '',
              })
              setError({
                first_name: '',
                last_name: '',
                email: '',
                message: '',
              })
            }}
            className='close close-btn'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body' style={{ background: '#fff' }}>
          <form onSubmit={handleGetInTouch}>
            <Row className='mb-2'>
              <div class='col-md-12 pb-3 text-center text-danger '>
                <strong>Note : </strong>
                <span className='text-small'>
                  Contact us for any show software related questions (not
                  pertaining to the show itself)
                </span>
              </div>
              <Col lg={12}>
                <div class='row'>
                  <div class='col-md-6'>
                    <div class='form-group mb-4'>
                      <input
                        id='first_name'
                        type='text'
                        name='first_name'
                        placeholder='First Name *'
                        class='form-control'
                        onChange={handleChange}
                        value={input.first_name || ''}
                        autoFocus={true}
                        maxlength='50'
                      />
                      {error?.first_name && (
                        <p style={{ color: 'red' }}>{error?.first_name}</p>
                      )}
                    </div>
                  </div>
                  <div class='col-md-6'>
                    <div class='form-group mb-4'>
                      <input
                        id='last_name'
                        type='text'
                        name='last_name'
                        placeholder='Last Name *'
                        class='form-control'
                        onChange={handleChange}
                        value={input.last_name || ''}
                        maxlength='50'
                      />
                      {error?.last_name && (
                        <p style={{ color: 'red' }}>{error?.last_name}</p>
                      )}
                    </div>
                  </div>

                  <div class='col-md-12'>
                    <div class='form-group mb-4'>
                      <input
                        id='email'
                        type='email'
                        name='email'
                        placeholder='Email *'
                        class='form-control'
                        onChange={handleChange}
                        value={input.email}
                      />
                      {error?.email && (
                        <p style={{ color: 'red' }}>{error?.email}</p>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <div class='col-md-12'>
                <div class='form-group mb-2'>
                  <textarea
                    class='form-control'
                    id='contact-message'
                    name='message'
                    rows='7'
                    placeholder='Message...'
                    onChange={handleChange}
                  >
                    {input.message}
                  </textarea>
                  {error?.message && (
                    <p style={{ color: 'red' }}>{error?.message}</p>
                  )}
                </div>
              </div>
              <GRecaptcha setGRC={setGRC} />

              <div class='col-md-12 pt-3 text-center'>
                <button
                  class='btn btn-primary'
                  type='submit'
                  id='btn-get-in-touch'
                  name='send'
                >
                  SUBMIT
                </button>
              </div>
            </Row>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ContactUs
