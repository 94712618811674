import { all, fork } from 'redux-saga/effects'
import getAdditionalProduct from './additionalProducts/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import AuthSaga from './auth/login/saga'
import ProfileSaga from './auth/profile/saga'
//public
import AccountSaga from './auth/register/saga'
import calendarSaga from './calendar/saga'
import chatSaga from './chat/saga'
import contactsSaga from './contacts/saga'
import DiscountSummary from './discount/saga'
import ecommerceSaga from './e-commerce/saga'
import easyEntrySaga from './easyEntry/saga'
import eventSaga from './event/saga'
import invoiceSaga from './invoices/saga'
import LayoutSaga from './layout/saga'
import manualEntry from './manualEntry/saga'
import shoppingCart from './shoppingCart/saga'
import purchasePlan from './purchasePlan/saga'
import yourParticipation from './yourParticipation/sagas'
//
import sponsorSagas from './sponsorEvent/saga'
import TraditionalPlanePaymentSagas from './TraditionalPayment/saga'
//

import BreedSagas from './Breeds/saga'
import ClassTypesSaga from './ClassTypes/saga'

//

import StateSaga from './state/saga'
import CountrySaga from './country/saga'
// Easyfides
import EasyFidesSaga from './easyFides/Profile/saga'
import MileSaga from './easyFides/Miles/saga'
import JudgeSaga from './easyFides/Judge/saga'
import MyAnimalSaga from './easyFides/Animal/saga'
import VendorCategorySaga from './easyFides/VendorCategory/saga'
import EquipmentSaga from './easyFides/Equipment/saga'
import InquirySaga from './easyFides/Inquiry/saga'
import premierMembershipSaga from './premierMembership/saga'
import premierReportSaga from './premierReport/saga'
import LiveEventSaga from './LiveEvent/saga'
//
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(ecommerceSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(eventSaga),
    fork(easyEntrySaga),
    fork(getAdditionalProduct),
    fork(shoppingCart),
    fork(purchasePlan),
    fork(DiscountSummary),
    fork(manualEntry),
    fork(sponsorSagas),
    fork(yourParticipation),
    fork(TraditionalPlanePaymentSagas),
    fork(EasyFidesSaga),
    fork(MileSaga),
    fork(JudgeSaga),
    fork(BreedSagas),
    fork(ClassTypesSaga),
    fork(MyAnimalSaga),
    fork(VendorCategorySaga),
    fork(EquipmentSaga),
    fork(InquirySaga),
    fork(StateSaga),
    fork(CountrySaga),
    fork(premierMembershipSaga),
    fork(premierReportSaga),
    fork(premierReportSaga),
    fork(LiveEventSaga),
  ])
}
