import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { Table } from 'react-super-responsive-table';
// import { getPlanDetail } from '../../store/purchasePlan/action';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanDetail } from '../../store/actions';
import { post } from '../../helpers/api_helper';

const PlanShoppingCart = () => {


  const dispatch = useDispatch();
  const history = useHistory();

  const { planDetails, loading } = useSelector((state) => state.purchasePlan);

  let plan = planDetails.length > 0 && planDetails[0];

  let info = localStorage.getItem('purchaseInfo');
  if (info) info = JSON.parse(info);

  const [checkoutLoading, setCheckoutLoading] = useState(false);

  useEffect(() => {
    if (!info) {
      // return <Redirect to="/" />;
      history.push('/login');
    }
    let planId = 1;
    if (info && info?.plan_type === 'hybrid') {
      planId = 2;
    } else if (info && info?.plan_type === 'integrated') {
      planId = 3;
    }
    dispatch(getPlanDetail(planId));
  }, []);

  const open = (info && info.open) || 0;
  const youth = (info && info.youth) || 0;
  const openAmount = (!loading && plan?.open_event_amt) || 0;
  const youthAmount = (!loading && plan?.youth_event_amt) || 0;

  const traditionalAmount = (!loading && parseInt(plan?.amount)) || 0;

  const onCheckout = (e) => {
    setCheckoutLoading(true);
    post(`/api/v1/plan-purchase/purchase-plan`, info)
      .then((response) => {
        const clientSecret = response.clientSecret;
        const paymentIntent = response.paymentIntent.id;
        info.payment_intent_id = paymentIntent;
        info.client_secret = clientSecret;

        setCheckoutLoading(false);

        history.push({
          pathname: '/plan_checkout',
          search: `?payment_intent_id=${paymentIntent}&client_secret=${clientSecret}`,
        });
      })
      .catch((err) => {
        setCheckoutLoading(false);
      });

    if (plan && plan.plan_name === 'Traditional') {
      info.totalAmount = traditionalAmount * info.club_list.length;
    } else {
      info.totalAmount = openAmount * open + youthAmount * youth;
    }
    localStorage.setItem('purchaseInfo', JSON.stringify(info));
  };

  // if (!info) {
  //   return <Redirect to="/hybrid_plan" />;
  // }
  // if (!plan || !info) {
  //   return <Redirect to="/login" />;
  // }
  // console.log(plan.plan_name);

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101">
        <section id="about" class="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="mt-5 text-center">
                <h2 className="top-title-plan mb-4">
                  <span>Shopping Cart</span>
                </h2>
              </Col>
            </Row>

            <Row>
              <Col xl={12} className="plan-structure mt-5">
                <div className="page-title-box justify-content-between">
                  <h2 className="mt-4 top-title mb-4 text-center">
                    {plan && <span>{`${plan.plan_name} plan` || ''}</span>}
                  </h2>
                </div>


                
                 {/* Traditional */}
                 { plan.plan_name === 'Traditional' ?    
                  <Col lg={12}>
                    <div className="plan-tab table-responsive mb-5">
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color">
                          <tr>
                            <th>Traditional Plan Details</th>
                            <th style={{ textAlign: 'center' }}>Availability</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Exhibitor's Enter Online</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Secretary manually enters entries</td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>Exhibitor manages entry changes</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              Automatic Entry Confirmation ( check in sheet )
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              Allow day of show changes ( restrictions apply )
                            </td>
                            <td style={{ textAlign: 'center' }}>N/A</td>
                          </tr>
                          <tr>
                            <td>Accept Online Entry Payments </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Optional add-ons for exhibitor's to purchase</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Software Usage Fees</td>
                            <td style={{ textAlign: 'center' }}>Annual</td>
                          </tr>
                          <tr>
                            <td>
                              Software is web based - no download or yearly
                              updating required
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>
                              Record Placement / Comments at the Judging Table
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              1-button click to send all Exhibitors Reports & Legs
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>

                          <tr>
                            <td colSpan={2} className='text-center'>
                                { plan.plan_name === 'Hybrid'
                                    ?    <strong className='text-red'> Hybrid shows also have a 10 cent per entry (breed and fur) fee to be paid after the show but before reports are sent out.  "No-Shows" are not charged this fee.   </strong>
                                : ' - '}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              * Optional - secretaries can manually enter entries
                              in all plans but required in Traditional
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              ** Traditional - 1 plan per club for 1 year
                              unlimited shows / events
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  :  null }



                 {/* Hybrid */}
                  { plan.plan_name === 'Hybrid' ?                 
                  <Col lg={12}>
                  <div className="plan-tab table-responsive mb-5">
                    <Table className="table mb-0 cust-tab">
                      <thead className="table-color">
                        <tr>
                          <th>Hybrid Plan Details</th>
                          <th style={{ textAlign: 'center' }}>Availability</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Exhibitor's Enter Online</td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>
                        <tr>
                          <td>Secretary manually enters entries</td>
                          <td style={{ textAlign: 'center' }}>Optional</td>
                        </tr>
                        <tr>
                          <td>Exhibitor manages entry changes</td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>

                        <tr>
                          <td>
                            Automatic Entry Confirmation ( check in sheet )
                          </td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>
                        <tr>
                          <td>
                            Allow day of show changes ( restrictions apply )
                          </td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>
                        <tr>
                            <td>Accept Online Entry Payments </td>
                            <td></td>
                          </tr>
                        <tr>
                          <td>Optional add-ons for exhibitor's to purchase</td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>
                        <tr>
                          <td>Software Usage Fees</td>
                          <td style={{ textAlign: 'center' }}>Per Event / Show</td>
                        </tr>
                        <tr>
                          <td>
                            Software is web based - no download or yearly
                            updating required
                          </td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>
                        <tr>
                          <td>
                            Record Placement / Comments at the Judging Table
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            1-button click to send all Exhibitors Reports & Legs
                          </td>
                          <td style={{ textAlign: 'center' }}>X</td>
                        </tr>

                        <tr>
                          <td colSpan={2} className='text-center'>
                              { plan.plan_name === 'Hybrid'
                                  ?    <strong className='text-red'> Hybrid shows also have a 10 cent per entry (breed and fur) fee to be paid after the show but before reports are sent out.  "No-Shows" are not charged this fee.   </strong>
                              : ' - '}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>
                            * Optional - secretaries can manually enter entries
                            in all plans but required in Traditional
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>
                            ** Traditional - 1 plan per club for 1 year
                            unlimited shows / events
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                  :  null }

            
                  {/* Integrated */}
                  { plan.plan_name === 'Integrated' ?    
                  <Col lg={12}>
                    <div className="plan-tab table-responsive mb-5">
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color">
                          <tr>
                            <th>Integrated Plan Details</th>
                            <th style={{ textAlign: 'center' }}>Availability</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Exhibitor's Enter Online</td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>Secretary manually enters entries</td>
                            <td style={{ textAlign: 'center' }}>Optional</td>
                          </tr>
                          <tr>
                            <td>Exhibitor manages entry changes</td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>

                          <tr>
                            <td>
                              Automatic Entry Confirmation ( check in sheet )
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>
                              Allow day of show changes ( restrictions apply )
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>Accept Online Entry Payments </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>Optional add-ons for exhibitor's to purchase</td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>Software Usage Fees</td>
                            <td style={{ textAlign: 'center' }}>Per Event / Show</td>
                          </tr>
                          <tr>
                            <td>
                              Software is web based - no download or yearly
                              updating required
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>
                          <tr>
                            <td>
                              Record Placement / Comments at the Judging Table
                            </td>
                            <td style={{ textAlign: 'center' }}>X (Optional)</td>
                          </tr>
                          <tr>
                            <td>
                              1-button click to send all Exhibitor Reports & Legs
                            </td>
                            <td style={{ textAlign: 'center' }}>X</td>
                          </tr>

                          <tr>
                            <td colSpan={2} className='text-center'>
                                { plan.plan_name === 'Hybrid'
                                    ?    <strong className='text-red'> Hybrid shows also have a 10 cent per entry (breed and fur) fee to be paid after the show but before reports are sent out.  "No-Shows" are not charged this fee.   </strong>
                                : ' - '}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              * Optional - secretaries can manually enter entries
                              in all plans but required in Traditional
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              ** Traditional - 1 plan per club for 1 year
                              unlimited shows / events
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                    :  null }


                <Col lg={12}>
                  <div className="table-responsive">
                    {plan && plan.plan_name !== 'Traditional' ? (
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color">
                          <tr>
                            <th>Event Type</th>
                            <th>Qty/Price</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {open !== 0 && (
                            <tr>
                              <td>Open</td>
                              <td>
                                {open} Qty X $ {parseInt(openAmount).toFixed(2)}
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                $ {(openAmount * open).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {youth !== 0 && (
                            <tr>
                              <td>Youth</td>
                              <td>
                                {youth} Qty X ${' '}
                                {parseInt(youthAmount).toFixed(2)}
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                $ {(youthAmount * youth).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          <tr className="total-box">
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                              <h5>TOTAL</h5>{' '}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <h5>
                                ${' '}
                                {(
                                  openAmount * open +
                                  youthAmount * youth
                                ).toFixed(2)}
                              </h5>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    ) : (
                      <Table className="table mb-0 cust-tab">
                        <thead className="table-color">
                          <tr>
                            <th>No</th>
                            <th>Club name</th>
                            <th>License Price</th>
                            <th style={{ textAlign: 'right' }}>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {info?.club_list.map((club, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{club.club_name}</td>
                              <td>${parseInt(traditionalAmount).toFixed(2)}</td>
                              <td style={{ textAlign: 'right' }}>
                                ${traditionalAmount.toFixed(2)}
                              </td>
                            </tr>
                          ))}
                          <tr className="total-box">
                            <td colSpan={3} style={{ textAlign: 'center' }}>
                              <h5>TOTAL</h5>{' '}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <h5>
                                $
                                {(
                                  traditionalAmount * info?.club_list.length
                                ).toFixed(2)}
                              </h5>{' '}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </Col>

                <Col lg={12} className="text-center mt-5 mb-5">
                  {/* <Link to="/plan_checkout"> */}
                  <button
                    type="button"
                    className="btn btn-primary btn-easy"
                    onClick={(e) => onCheckout(e)}
                    disabled={checkoutLoading}
                  >
                    PROCEED TO CHECKOUT
                  </button>
                  {/* </Link> */}
                </Col>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default PlanShoppingCart;
