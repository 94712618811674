import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { premierUserResetCardDetail, premierUsers } from '../../store/actions'
import moment from 'moment'
import { getDateWithFormat } from '../../helpers/date_helper'
const Data = [
  {
    plan: 'Basic',
    isActive: true,
    list: [
      // {
      //   item: 'View Show Reports',
      //   icon: <i className="fas fa-times-circle text-danger"></i>,
      //   highlightColor: 'red',
      // },
      // {
      //   item: 'View "LIVE" class placement',
      //   icon: <i className="fas fa-times-circle text-danger"></i>,
      //   highlightColor: 'red',
      // },
      {
        item: 'View upcoming events',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'View entry count by breed',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Online entry portal',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Easy Entry',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Easyfieds',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
    ],
  },
  {
    plan: 'Premier',
    isActive: false,
    list: [
      {
        item: 'View Show Reports',
        icon: <i className='fas fa-check-circle text-danger'></i>,
        //#29BF12
        highlightColor: 'red',
      },
      {
        item: 'View "LIVE" class placement',
        icon: <i className='fas fa-check-circle text-danger'></i>,
        highlightColor: 'red',
      },
      {
        item: 'View upcoming events',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'View entry count by breed',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Online entry portal',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Easy Entry',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
      {
        item: 'Easyfieds',
        icon: <i className='fas fa-check-circle text-success gr-icon'></i>,
        highlightColor: '#60993E',
      },
    ],
  },
]
const PremiumMembership = () => {
  const dispatch = useDispatch()
  const { user, premierUser } = useSelector((state) => state.Login)
  // const navigate =
  useEffect(() => {
    dispatch(premierUserResetCardDetail())
    if (user?.id) {
      let data = {
        user_id: user.id,
      }
      dispatch(premierUsers(data))
    }
  }, [user?.id])

  const isDateFormat = useMemo(() => {
    var novemberDate = moment('2024-11-01')
    var nowDate = moment(getDateWithFormat(new Date().toDateString()))

    return novemberDate.diff(nowDate, 'days')
  }, [])
  // const user = JSON.parse(localStorage.getItem('authUser'))
  if (premierUser?.premier_exhibitor_id) {
    return <Redirect to='/' />
  }

  return (
    <section className='section mt-10'>
      <Container fluid>
        <Row>
          <Col md='12' className='text-center mt-3'>
            <h2 className='top-title text-center'>
              <span>Get Start Today</span>
            </h2>
          </Col>
        </Row>
        <Row className='d-flex justify-content-center mt-10 res-mt-10'>
          <Col md='9' lg='9' sm='12' xl='9' xs='12'>
            <Row className='d-flex flex-wrap justify-content-between'>
              {Array.isArray(Data) && Data.length
                ? Data.map((plan) => {
                    return (
                      <Col md={12} sm='12' xs='12' lg='6' xl='6'>
                        <Card>
                          <CardHeader className='bg-primary1 text-white text-center bg-plan'>
                            <h4 className='mb-2 mt-2'>{plan?.plan}</h4>
                          </CardHeader>
                          <CardBody className='text-center bg-f5'>
                            <ListGroup flush>
                              {Array.isArray(plan.list) && plan.list.length
                                ? plan.list.map((list, index) => {
                                    return (
                                      <ListGroupItem
                                        key={index}
                                        className='w-100 d-flex flex-wrap justify-content-start align-items-center'
                                      >
                                        {' '}
                                        {list.icon}
                                        <strong
                                          className='ms-3'
                                          style={{
                                            color:
                                              list?.highlightColor || 'black',
                                          }}
                                        >
                                          {list?.item}
                                        </strong>
                                      </ListGroupItem>
                                    )
                                  })
                                : null}
                              {plan?.isActive ? (
                                <ListGroupItem className='min-card pt-10'>
                                  <strong>Price $0.00</strong>
                                </ListGroupItem>
                              ) : (
                                <ListGroupItem className='min-card1'>
                                  {isDateFormat >= 0 ? (
                                    <strong>
                                      <span className='text-decoration-line-through text-danger'>
                                        $24.00
                                      </span>{' '}
                                      per year <br />
                                      special price{' '}
                                      <strong>
                                        <span className='text-danger'>
                                          $20.00{' '}
                                        </span>
                                      </strong>
                                      until Nov. 1st, 2024 <br />{' '}
                                      <span className='tax'>
                                        Plus 7% State Sales Tax, 3% Processing
                                        fees
                                      </span>
                                    </strong>
                                  ) : (
                                    <strong>Price $24.00</strong>
                                  )}
                                </ListGroupItem>
                              )}
                            </ListGroup>
                          </CardBody>
                          <CardFooter>
                            {plan?.isActive ? (
                              <Button
                                color='info'
                                block
                                className='w-100 curr-plan'
                                disabled
                                title='Current Active Plan'
                              >
                                CURRENT ACTIVE PLAN
                              </Button>
                            ) : (
                              <Link
                                to={
                                  user?.id
                                    ? '/premier-membership/checkout'
                                    : '/login'
                                }
                                color='primary'
                                block
                                className='w-100 btn btn-primary'
                                title='Purchase'
                              >
                                PURCHASE
                              </Link>
                            )}
                          </CardFooter>
                        </Card>
                      </Col>
                    )
                  })
                : null}
              {!user?.id ? (
                <Col
                  md='12'
                  className='text-dark fw-bolder fs-41 mt-4 pre-text'
                >
                  You need to have a basic profile created (should have a login
                  account setup to upgrade to premier) if you do not have a
                  basic account please{' '}
                  <Link to='/register' className='text-decoration-underline'>
                    click here
                  </Link>{' '}
                  to register
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PremiumMembership
